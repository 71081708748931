import React from 'react';
import { Link as GatsbyLink, graphql } from 'gatsby';
import Img from 'gatsby-image';
import BackgroundImage from 'gatsby-background-image';

import { MdChevronRight } from 'react-icons/md';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Hero from '../components/Hero';

const Services = ({ data }) => (
  <Layout>
    <SEO
      meta_title={data.prismicServices.data.meta_title}
      meta_description={data.prismicServices.data.meta_description}
      hero_image={data.prismicServices.data.hero_image.url}
    />
    <Hero
      title={
        <div
          dangerouslySetInnerHTML={{
            __html: data.prismicServices.data.hero_text.html,
          }}
        />
      }
      overlay="bg-black opacity-50"
      imagebg={data.prismicServices.data.hero_image.localFile.childImageSharp.fluid}
    />
    <section className="p-0">
      <div className="flex flex-wrap text-white">
        <BackgroundImage className="w-full relative py-16" fluid={data.mitigationImage.childImageSharp.fluid}>
          <div className="container z-10 relative">
            <div className="flex flex-wrap md:-mx-6">
              <div className="w-full md:w-1/2 md:px-6">
                <h3 id={data.mitigationTag.id}>{data.mitigationTag.data.tag.text}</h3>
              </div>
              <div className="w-full md:w-1/2 md:px-6">
                <ul className="list-reset m-0 p-0 flex flex-wrap md:-mx-6">
                  {data.mitigation.edges.map(({ node }) => (
                    <li key={node.id} className="w-full md:w-1/2 md:px-6">
                      <GatsbyLink
                        to={`services/${node.uid}`}
                        className="no-underline border-b border-white block py-2 text-white flex justify-between hover:text-white hover:bg-red-darker inline-block"
                      >
                        <span className="pl-2">{node.data.title.text}</span>
                        <span className="flex self-center text-xl">
                          <MdChevronRight />
                        </span>
                      </GatsbyLink>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </BackgroundImage>

        <div className="flex flex-wrap w-full">
          <BackgroundImage className="w-full relative py-32" fluid={data.remodelingImage.childImageSharp.fluid}>
            <div className="container z-10 relative">
              <div className="flex flex-wrap md:-mx-6">
                <div className="w-full md:w-1/2 md:px-6">
                  <h3 id={data.remodelingTag.id}>{data.remodelingTag.data.tag.text}</h3>
                </div>
                <div className="w-full md:w-1/2 md:px-6">
                  <ul className="list-reset m-0 p-0">
                    {data.remodeling.edges.map(({ node }) => (
                      <li key={node.id} className="w-full">
                        <GatsbyLink
                          to={`services/${node.uid}`}
                          className="no-underline border-b border-white block py-4 text-white flex justify-between hover:text-white hover:bg-blue-darker inline-block"
                        >
                          <span className="pl-2">{node.data.title.text}</span>
                          <span className="flex self-center text-xl">
                            <MdChevronRight />
                          </span>
                        </GatsbyLink>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </BackgroundImage>
        </div>
      </div>
    </section>

    <section className="py-0">
      <div className="flex flex-wrap">
        {data.prismicServices.data.gallery.map(({ galley_image }, id) => (
          <div key={id} className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4">
            <Img fluid={galley_image.localFile.childImageSharp.fluid} />
          </div>
        ))}
      </div>
    </section>
  </Layout>
);

export default Services;

export const query = graphql`
  query servicesPage {
    prismicServices {
      uid
      id
      data {
        meta_title
        meta_description
        title {
          text
        }
        content {
          html
        }
        hero_image {
          url
          localFile {
            childImageSharp {
              fluid(maxWidth: 1600, maxHeight: 600, quality: 80, cropFocus: CENTER) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        hero_text {
          html
        }
        gallery {
          galley_image {
            alt
            localFile {
              childImageSharp {
                fluid(maxWidth: 600, maxHeight: 600, quality: 80, cropFocus: CENTER) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          image_caption {
            text
          }
        }
      }
    }
    remodeling: allPrismicService(
      filter: { data: { service_type: { eq: "Remodeling and Construction" } } }
      sort: { fields: data___title___text, order: ASC }
    ) {
      edges {
        node {
          ...serviceFragment
        }
      }
    }
    mitigation: allPrismicService(
      filter: { data: { service_type: { eq: "Mitigation and Emergency Services" } } }
      sort: { fields: data___title___text, order: ASC }
    ) {
      edges {
        node {
          ...serviceFragment
        }
      }
    }

    mitigationImage: file(relativePath: { eq: "structure-plus_10810626_xl-2015-red.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1600, maxHeight: 1000, quality: 80, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    remodelingImage: file(relativePath: { eq: "structure-plus_8656403_xl-2015-blue.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1600, maxHeight: 1000, quality: 80, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    mitigationTag: prismicTags(data: { tag: { text: { eq: "Mitigation and Emergency Services" } } }) {
      id
      data {
        tag {
          text
        }
        tag_description {
          html
        }
      }
    }
    remodelingTag: prismicTags(data: { tag: { text: { eq: "Remodeling and Construction Services" } } }) {
      id
      data {
        tag {
          text
        }
        tag_description {
          html
        }
      }
    }
  }

  fragment serviceFragment on PrismicService {
    uid
    id
    data {
      title {
        html
        text
      }
      content {
        html
        text
      }
      service_type
    }
  }
`;
